import React from 'react';

class Home extends React.Component {
  render() {
    return(
      <div id="titlebox">
        <h1 id="name">KEVIN PRIMAT</h1>
        <h2 id="attributes">Entrepreneur, investor, voracious reader</h2>
      </div>

    )
  }
}
export default Home
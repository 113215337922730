import React from 'react';

class Articles extends React.Component {
  render() {
    return(
      <h1>Articles</h1>
    )
  }
}

export default Articles;
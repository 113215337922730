import React from 'react';
import data from "./data";


class Bookshelf extends React.Component {

  state = {
    data: [],
    id: 0,
    Title: null,
    Subtitle: null,
    Category: null,
    isLoading: false,
    // intervalIsSet: true
  };


  componentDidMount = () => {
  }


  componentWillUnmount(){
  }


  render() {

    return(
      <div className="bookshelf">
          {
                  data.map((book, i) => {
                    return (
                      <ul key={i}>
                        <li>
                          <a href={book.Link}>{book.Title}</a>
                          {book.Subtitle ? ": " + book.Subtitle : ""}
                        </li>
                      </ul>
                    );
                  })
                }
      </div>
    )
  }
}

export default Bookshelf;
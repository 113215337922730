import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch, Link, BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import './styles.css';

// import App from './App';
import Home from './home'
import Books from './books'
import Articles from './articles'



const routing = (
  <Router>
    <div >
      <ul id="navbox">
        <Link to="/" className="li-horizontal">home</Link>
        <Link to="/books" className="li-horizontal">books</Link>
        <li className="li-horizontal"><a href="https://www.linkedin.com/in/kevinprimat">linkedin</a></li>
        <li className="li-horizontal"><a href="https://twitter.com/kevinprimat">tweets</a></li>
      </ul>
       <Switch>
        <Route path="/books" component={Books} />
        <Route path="/articles" component={Articles} />
        <Route path="/" component={Home} />
      </Switch>
    </div>
  </Router>
)

ReactDOM.render(routing, document.getElementById('root'));

serviceWorker.unregister();
